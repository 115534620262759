// extracted by mini-css-extract-plugin
export var ul = "MobileNav-module--ul--HNQ2v";
export var logo = "MobileNav-module--logo--3VYY1";
export var link = "MobileNav-module--link--mbgGa";
export var socialLink = "MobileNav-module--socialLink--1nnu7";
export var nav = "MobileNav-module--nav--npl-B";
export var socialIcons = "MobileNav-module--socialIcons--2A4Lq";
export var icon = "MobileNav-module--icon--1OU84";
export var closeIcon = "MobileNav-module--closeIcon--28fYL";
export var menuIcon = "MobileNav-module--menuIcon--2sGqR";
export var menuIconWrapper = "MobileNav-module--menuIconWrapper--39GW8";
export var menuText = "MobileNav-module--menuText--3zp5b";
export var closeMenu = "MobileNav-module--closeMenu--1WAhR";
export var wrapper = "MobileNav-module--wrapper--1JF7P";